.App {
  text-align: center;
}

.lessons-list {
  width: 70%;
  display: inline-flex;
  flex-direction: column;
}

.lesson-button {
  font-weight: bold;
  cursor: pointer;
  margin: 20px 0;
  /*padding: 20px;*/
  width: 100%;
  height: 112px;
  background-color: #ffffff;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
}

.lesson-image {
  width: 64px;
  height: 64px;
  border-radius: 12px;
  background-color: rebeccapurple;
  opacity: 0.2;
  margin: 0 24px;
  box-sizing: border-box;
}

.lesson-text {
  display: inline-flex;
  align-items: flex-start;
  width: 100%;
  margin-left: 42px;
  flex-direction: column;
}

.lesson-number {
  /* Desktop/subtitle */
  font-family: sans-serif;
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 16px;

  /* identical to box height, or 133% */
  letter-spacing: 1px;
  text-transform: uppercase;

  /* ELive/Tints/Slate 5 */
  color: #736c91;
}

.lesson-name {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;

  /* identical to box height, or 140% */

  /* ELive/Contrast/EF Black */
  color: #191919;
}

.lesson-player {
  width: 100%;
  margin: 20px 0;
  height: 820px;
  border-radius: 12px;
  background-color: #ffffff;
}

.catalyst-iframe {
  width: 100%;
  height: 100%;

  border-radius: 12px;
}

.catalyst-iframe.fullscreen {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  min-height: 600px;
  width: 100%;
}
